/**
 * @generated SignedSource<<14be8c1116d6a5579a23b6cdb2bd361f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSyncQueryRefetch$variables = {};
export type UserSyncQueryRefetch$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"UserSync_viewer">;
  };
};
export type UserSyncQueryRefetch = {
  response: UserSyncQueryRefetch$data;
  variables: UserSyncQueryRefetch$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSyncQueryRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSync_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSyncQueryRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSuperAdmin",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b662626cc7949d2c0a1d93885ecd0ba1",
    "id": null,
    "metadata": {},
    "name": "UserSyncQueryRefetch",
    "operationKind": "query",
    "text": "query UserSyncQueryRefetch {\n  viewer {\n    ...UserSync_viewer\n  }\n}\n\nfragment UserSync_viewer on Viewer {\n  me {\n    id\n    email\n    isSuperAdmin\n  }\n}\n"
  }
};

(node as any).hash = "b13e84eb7898fc46b3a8968fc9fe0654";

export default node;
