import './index.css'
import 'core-js'
import '@portal/components/font/inter/inter.css'
import '@portal/components/font/ibmPlexMono/ibmPlexMono.css'
import '@portal/components/font/inconsolata/inconsolata.css'
import '@portal/components/font/aeonikPro/aeonik.css'
import 'overlayscrollbars/overlayscrollbars.css'

import type { Breadcrumb } from '@sentry/browser'
import * as Sentry from '@sentry/browser'
import {
  CaptureConsole as CaptureConsoleIntegration,
  Dedupe as DedupeIntegration,
  Offline as OfflineIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import App from 'App'
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

const windowEnvironment = window._env_

if (windowEnvironment.APP_SENTRY_DSN_URL) {
  Sentry.init({
    dsn: windowEnvironment.APP_SENTRY_DSN_URL,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
      new DedupeIntegration(),
      new OfflineIntegration(),
      new ReportingObserverIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: windowEnvironment.APP_ENV,
    // Before an event is emitted, attach a breadcrumb that contains
    // the version of the portal.
    beforeSend(event) {
      const defaultBreadcrum: Breadcrumb = {
        category: 'versions',
        level: 'debug',
        data: {
          PORTAL_FRONTEND_BUILD_VERSION: windowEnvironment.BUILD_VERSION,
        },
      }
      if (event.breadcrumbs) {
        event.breadcrumbs.push(defaultBreadcrum)
      } else {
        event.breadcrumbs = [defaultBreadcrum]
      }

      return event
    },
  })
}

if (
  windowEnvironment.GOOGLE_TAG_MANAGER_KEY &&
  windowEnvironment.GOOGLE_TAG_MANAGER_ENV_AUTH &&
  windowEnvironment.GOOGLE_TAG_MANAGER_PREVIEW_ENV_ID
) {
  TagManager.initialize({
    gtmId: windowEnvironment.GOOGLE_TAG_MANAGER_KEY,
    auth: windowEnvironment.GOOGLE_TAG_MANAGER_ENV_AUTH,
    preview: windowEnvironment.GOOGLE_TAG_MANAGER_PREVIEW_ENV_ID,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
