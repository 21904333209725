import { Container } from 'components/layout'
import React from 'react'

import { useEmployeeGroupContext } from './context'

export default function EmployeeGroupHeader() {
  const { name } = useEmployeeGroupContext()

  return (
    <div className="bg-background">
      <Container>
        <div className="flex flex-col justify-between pb-6 pt-4 lg:flex-row">
          <div className="mb-4 lg:mb-0">
            <h1
              className="font-aeonik text-2xl font-medium antialiased sm:text-3xl"
              data-testid="employee-group-name"
            >
              {name}
            </h1>
            <p className="font-aeonik text-base text-foreground-subtle">
              Employee group
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}
