/**
 * @generated SignedSource<<15e1bea802631108be067297d3b864f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppTenantMenuQuery$variables = {
  skipOrganization: boolean;
  slug: string;
};
export type AppTenantMenuQuery$data = {
  readonly viewer: {
    readonly organization?: {
      readonly id: string;
      readonly name: string;
    } | null;
  };
};
export type AppTenantMenuQuery = {
  response: AppTenantMenuQuery$data;
  variables: AppTenantMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipOrganization"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "condition": "skipOrganization",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "slug",
                "variableName": "slug"
              }
            ],
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppTenantMenuQuery",
    "selections": (v2/*: any*/),
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppTenantMenuQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fce5f751351c701ca6130985a2399bed",
    "id": null,
    "metadata": {},
    "name": "AppTenantMenuQuery",
    "operationKind": "query",
    "text": "query AppTenantMenuQuery(\n  $slug: String!\n  $skipOrganization: Boolean!\n) {\n  viewer {\n    organization(slug: $slug) @skip(if: $skipOrganization) {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ab63ada4ee7cddba0c6cf071c139116";

export default node;
