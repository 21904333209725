const requiredBrowserAPIs = [
  'BigInt',
  'WeakRef',
  'FinalizationRegistry',
] as const

// Function used to detect whether the current browser supports all the APIs required by Ditto
export const isSupportedBrowser = () => {
  // From https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
  const isIE =
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > -1

  const hasSupportedBrowserAPIs = requiredBrowserAPIs.every(
    (api) => !!window[api],
  )

  return !isIE && hasSupportedBrowserAPIs
}
