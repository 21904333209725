import graphql__53380c3069bc666d9a29 from "./__generated__/AppAuthRouterQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import { Container } from 'components/layout'
import React, { Suspense, useEffect } from 'react'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import { Route, Routes } from 'react-router-dom'

import AppManagementRouterRedirect from '../../AppManagementRouterRedirect'
import { AppAuthRouterQuery } from './__generated__/AppAuthRouterQuery.graphql'

const CreateApiKeyScene = React.lazy(() => import('./apiKey/CreateApiKeyScene'))
const AppAuthScene = React.lazy(() => import('./AppAuthScene'))

export const query = graphql__53380c3069bc666d9a29

type ContainerProps = {
  appId: string
}

export default function AppAuthRouterContainer({ appId }: ContainerProps) {
  const [queryRef, loadQuery] = useQueryLoader<AppAuthRouterQuery>(query)

  useEffect(() => {
    loadQuery(
      { appId },
      {
        fetchPolicy: 'store-and-network',
      },
    )
  }, [loadQuery, appId])

  if (!queryRef) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <AppAuthRouter queryRef={queryRef} appId={appId} />
    </Suspense>
  )
}

type Props = {
  queryRef: PreloadedQuery<AppAuthRouterQuery>
} & ContainerProps

function AppAuthRouter({ appId, queryRef }: Props) {
  const { viewer } = usePreloadedQuery<AppAuthRouterQuery>(query, queryRef)

  const canCreateApiKey = viewer.appById?.viewerCanCreateApiKey || false

  return (
    <Container>
      <Routes>
        <Route path="" element={<AppAuthScene appId={appId} />} />
        {canCreateApiKey && (
          <Route
            path="/api-keys/new"
            element={<CreateApiKeyScene appId={appId} />}
          />
        )}
        <Route path="*" element={<AppManagementRouterRedirect area="auth" />} />
      </Routes>
    </Container>
  )
}
