/**
 * @generated SignedSource<<74b920fd62504bb7befa223b27eb3a59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationsMenuItemQuery$variables = {};
export type OrganizationsMenuItemQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationsMenuItem_viewer">;
  };
};
export type OrganizationsMenuItemQuery = {
  response: OrganizationsMenuItemQuery$data;
  variables: OrganizationsMenuItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationsMenuItemQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationsMenuItem_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationsMenuItemQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizations",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "591455670968c8852d42820f82738151",
    "id": null,
    "metadata": {},
    "name": "OrganizationsMenuItemQuery",
    "operationKind": "query",
    "text": "query OrganizationsMenuItemQuery {\n  viewer {\n    ...OrganizationsMenuItem_viewer\n  }\n}\n\nfragment OrganizationsMenuItem_viewer on Viewer {\n  me {\n    id\n    organizations {\n      id\n      name\n      slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4d6cc1e5e44d6f3b78f71eac669698a4";

export default node;
