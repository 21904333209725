import { useAuth0 } from '@auth0/auth0-react'
import { AlertProvider } from '@portal/components'
import { QueryClientProvider } from '@tanstack/react-query'
import { FullPageLoader } from 'components/loader'
import RelayProvider from 'components/relay'
import { TenantContextProvider } from 'components/tenant'
import UserSync from 'components/userSync'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { ToastProvider } from 'react-toast-notifications'
import resolveConfig from 'tailwindcss/resolveConfig'
import { useNavigateRef } from 'utils/navigate'
import { queryClient } from 'utils/queryClient'

import tailwindConfig from '../tailwind.config.js'
import AppRouter from './AppRouter'
import AppContext from './context'
import DittoPortalProvider from './dittoPortalProvider'
import NavigationBarRouter from './NavigationBarRouter'

const tailwind = resolveConfig(tailwindConfig)

/** Entry point for the app, where we handle user authentication. */
function AppEntry() {
  const navigate = useNavigateRef()
  const {
    error,
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      if (window.location.pathname === '/create-account') {
        const searchParams = new URLSearchParams(window.location.search)
        const values = searchParams.get('values')
        loginWithRedirect({
          action: 'signup',
          values: values ?? undefined,
          appState: { redirectUrl: '/' },
        })
      } else {
        loginWithRedirect({
          action: 'login',
          appState: {
            redirectUrl: window.location.pathname,
            searchParams: window.location.search,
          },
        })
      }
    }
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    error,
  ])

  const getToken = useCallback(
    () => getAccessTokenSilently({ ignoreCache: false }),
    [getAccessTokenSilently],
  )

  const isUnverifiedEmailError = useMemo(
    () => error?.message?.toLowerCase() === 'email not verified',
    [error],
  )
  /**
   * An effect to redirect the user to the appropriate page if they have an expired or unverified email.
   *
   * Note: This should ideally handle other error cases, as well. Currently, it only handles the two cases.
   */
  useEffect(() => {
    if (isUnverifiedEmailError) {
      navigate('/email-verification-pending')
    }
  }, [isUnverifiedEmailError, navigate])

  const isAuthComplete = isAuthenticated && !isLoading && !error

  /**
   * An effect to remove any query params from the URL after the user has successfully logged in. Some
   * query params linger after the email confirmation flow, and while they don't cause any issues, they
   * are a bit of an eyesore.
   *
   * This was added as part of a fix for https://github.com/getditto/cloud-services/issues/2322
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const shouldClearSearchParams =
      isAuthComplete && searchParams.toString().length > 0

    if (shouldClearSearchParams) {
      window.history.replaceState({}, '', window.location.pathname)
    }
  }, [isAuthComplete])

  if (isLoading) {
    return <FullPageLoader />
  }

  if (!isAuthenticated && !isLoading) {
    return null
  }

  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <RelayProvider token={getToken}>
          <Suspense fallback={<FullPageLoader />}>
            <UserSync user={user!}>
              {(authenticatedUser) => (
                <AlertProvider>
                  <AppContext.Provider
                    value={{
                      tailwind: tailwind,
                      jwtToken: getToken,
                      isSuperAdmin: !!authenticatedUser?.isSuperAdmin,
                    }}
                  >
                    <DittoPortalProvider>
                      <TenantContextProvider>
                        <NavigationBarRouter />
                        <AppRouter />
                      </TenantContextProvider>
                    </DittoPortalProvider>
                  </AppContext.Provider>
                </AlertProvider>
              )}
            </UserSync>
          </Suspense>
        </RelayProvider>
      </QueryClientProvider>
    </ToastProvider>
  )
}

export default withLDProvider({
  clientSideID: window._env_.LD_CLIENT_ID,
  options: {
    application: {
      id: 'portal_frontend',
      version: window._env_.BUILD_VERSION,
    },
  },
  // NOTE: We are creating a default, anonymous context here with a fixed key in order to avoid creating
  // an abundance of anonymous contexts. Without this, LaunchDarkly would create an anonymous context with a
  // unique key, which would drive up MAU figures in LaunchDarkly. See https://github.com/getditto/cloud-services/issues/2187
  context: {
    anonymous: true,
    key: 'anonymous-user',
    kind: 'user',
  },
})(AppEntry)
