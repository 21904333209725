import React from 'react'
import { Route, Routes } from 'react-router-dom'

const AppDevicesScene = React.lazy(() => import('./AppDevicesScene'))
const DeviceDetailsScene = React.lazy(
  () => import('./deviceDetails/DeviceDetailsScene'),
)

export default function AppDevicesRouter() {
  return (
    <Routes>
      <Route path="" element={<AppDevicesScene />} />
      <Route path=":peerKey" element={<DeviceDetailsScene />} />
    </Routes>
  )
}
