/**
 * @generated SignedSource<<2078b362b006fcd08b893037da981ea5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserAppIdResolverQuery$variables = {
  skipUser: boolean;
  skipViewer: boolean;
  userId: string;
};
export type UserAppIdResolverQuery$data = {
  readonly viewer: {
    readonly me?: {
      readonly apps: ReadonlyArray<{
        readonly id: string;
        readonly slug: string;
      }>;
      readonly id: string;
    } | null;
    readonly user?: {
      readonly apps: ReadonlyArray<{
        readonly id: string;
        readonly slug: string;
      }>;
      readonly id: string;
    } | null;
  };
};
export type UserAppIdResolverQuery = {
  response: UserAppIdResolverQuery$data;
  variables: UserAppIdResolverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipUser"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipViewer"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "App",
    "kind": "LinkedField",
    "name": "apps",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "condition": "skipViewer",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "skipUser",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId"
              }
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAppIdResolverQuery",
    "selections": (v5/*: any*/),
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserAppIdResolverQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "6f737e0720202581ae123b6a9b14c511",
    "id": null,
    "metadata": {},
    "name": "UserAppIdResolverQuery",
    "operationKind": "query",
    "text": "query UserAppIdResolverQuery(\n  $userId: ID!\n  $skipViewer: Boolean!\n  $skipUser: Boolean!\n) {\n  viewer {\n    me @skip(if: $skipViewer) {\n      id\n      apps {\n        id\n        slug\n      }\n    }\n    user(id: $userId) @skip(if: $skipUser) {\n      id\n      apps {\n        id\n        slug\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4910ca1281851f16c77ac6db79f46fd5";

export default node;
