import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function EmailConfirmationPendingScene() {
  const { isAuthenticated, error } = useAuth0()

  const navigate = useNavigate()
  const isUnverifiedEmailError =
    error?.message?.toLowerCase() === 'email not verified'

  useEffect(() => {
    if (isAuthenticated || !isUnverifiedEmailError) {
      navigate('/')
    }
  }, [isAuthenticated, isUnverifiedEmailError, navigate])

  if (isAuthenticated || !isUnverifiedEmailError) {
    return null
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center px-4 sm:px-0">
      <div className="flex max-w-lg flex-col gap-1.5">
        <h1 className="text-3xl font-bold">Email Confirmation Pending</h1>
        <p className="text-lg">
          Please check your associated email to verify your acount. You will not
          be granted access until you have done so. If you do not see the email,
          please check your spam folder. If your original link has expired, we
          have sent you a new link.
        </p>
        <p className="text-lg">
          If you experience issues confirming your account, please contact{' '}
          <a
            href="mailto:support@ditto.live"
            className="text-blue-500 underline"
          >
            support
          </a>
          .
        </p>
      </div>
    </div>
  )
}
