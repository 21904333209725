/**
 * @generated SignedSource<<7a7ff224458c0ec2953c3e29e323e55e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentOrganizationAppsMenuItem_viewer$data = {
  readonly me: {
    readonly id: string;
    readonly recentOrganizationApps: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly tenant: {
        readonly __typename: "Organization";
        readonly id: string;
        readonly name: string;
        readonly slug: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    }>;
  } | null;
  readonly " $fragmentType": "RecentOrganizationAppsMenuItem_viewer";
};
export type RecentOrganizationAppsMenuItem_viewer$key = {
  readonly " $data"?: RecentOrganizationAppsMenuItem_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentOrganizationAppsMenuItem_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./RecentOrganizationAppsRefetchQuery.graphql')
    }
  },
  "name": "RecentOrganizationAppsMenuItem_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "App",
          "kind": "LinkedField",
          "name": "recentOrganizationApps",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "tenant",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "type": "Organization",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a3ec6d26fb7fb391c1b48e8528e32488";

export default node;
