/**
 * @generated SignedSource<<365d1f39e86c7b6d66e391b5b751079c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserManagementHeader_userInfo$data = {
  readonly firstName: string;
  readonly lastName: string;
  readonly " $fragmentType": "UserManagementHeader_userInfo";
};
export type UserManagementHeader_userInfo$key = {
  readonly " $data"?: UserManagementHeader_userInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserManagementHeader_userInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserManagementHeader_userInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "86857dd260237f88f6e77918a7c806d0";

export default node;
