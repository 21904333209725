/**
 * @generated SignedSource<<035c02fcfd80299af7e0d645db804434>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSync_viewer$data = {
  readonly me: {
    readonly email: string;
    readonly id: string;
    readonly isSuperAdmin: boolean;
  } | null;
  readonly " $fragmentType": "UserSync_viewer";
};
export type UserSync_viewer$key = {
  readonly " $data"?: UserSync_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSync_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./UserSyncQueryRefetch.graphql')
    }
  },
  "name": "UserSync_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSuperAdmin",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "b13e84eb7898fc46b3a8968fc9fe0654";

export default node;
