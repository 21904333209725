/**
 * @generated SignedSource<<2a7e5ce9c36ab02e091172eadfb93502>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserMenuQuery$variables = {};
export type UserMenuQuery$data = {
  readonly viewer: {
    readonly me: {
      readonly email: string;
      readonly firstName: string;
      readonly hubspotContactId: string;
      readonly id: string;
      readonly imageUrl: string | null;
      readonly incomingAppRequests: ReadonlyArray<{
        readonly __typename: "AppTransferRequest";
      }>;
      readonly incomingOrganizationInvitations: ReadonlyArray<{
        readonly acceptedAt: string | null;
        readonly id: string;
        readonly rejectedAt: string | null;
      }>;
      readonly lastName: string;
      readonly organizations: ReadonlyArray<{
        readonly id: string;
        readonly incomingAppRequests: ReadonlyArray<{
          readonly __typename: "AppTransferRequest";
        }>;
        readonly ownershipChangeRequest: {
          readonly toMember: {
            readonly id: string;
            readonly user: {
              readonly id: string;
            };
          };
        } | null;
      }>;
    } | null;
  };
};
export type UserMenuQuery = {
  response: UserMenuQuery$data;
  variables: UserMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AppTransferRequest",
  "kind": "LinkedField",
  "name": "incomingAppRequests",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hubspotContactId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationOwnershipChangeRequest",
                "kind": "LinkedField",
                "name": "ownershipChangeRequest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Member",
                    "kind": "LinkedField",
                    "name": "toMember",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Invitation",
            "kind": "LinkedField",
            "name": "incomingOrganizationInvitations",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rejectedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acceptedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMenuQuery",
    "selections": (v2/*: any*/),
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserMenuQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b0cfb4aa53e576daa544590986a28bd5",
    "id": null,
    "metadata": {},
    "name": "UserMenuQuery",
    "operationKind": "query",
    "text": "query UserMenuQuery {\n  viewer {\n    me {\n      id\n      firstName\n      lastName\n      imageUrl\n      email\n      hubspotContactId\n      organizations {\n        id\n        incomingAppRequests {\n          __typename\n        }\n        ownershipChangeRequest {\n          toMember {\n            id\n            user {\n              id\n            }\n          }\n        }\n      }\n      incomingAppRequests {\n        __typename\n      }\n      incomingOrganizationInvitations {\n        id\n        rejectedAt\n        acceptedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6edca933ba988bc441de561f00fdbaf";

export default node;
