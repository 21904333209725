/**
 * @generated SignedSource<<57e20dfb302bd856f2accc3ab582da04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationAppsMenuItem_viewer$data = {
  readonly organization: {
    readonly apps: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly slug: string;
    }>;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "OrganizationAppsMenuItem_viewer";
};
export type OrganizationAppsMenuItem_viewer$key = {
  readonly " $data"?: OrganizationAppsMenuItem_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationAppsMenuItem_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./ViewerOrganizationAppsRefetchQuery.graphql')
    }
  },
  "name": "OrganizationAppsMenuItem_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "App",
          "kind": "LinkedField",
          "name": "apps",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a57c911c3078765c821bca86ea639bd8";

export default node;
