import { Container } from 'components/layout'
import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import EmployeeGroupHeader from './EmployeeGroupHeader'
import EmployeeGroupNavigation from './EmployeeGroupNavigation'

export default function EmployeeGroupManagementLayout() {
  return (
    <>
      <EmployeeGroupHeader />
      <EmployeeGroupNavigation />
      <Container className="py-8">
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </Container>
    </>
  )
}
