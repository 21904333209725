/**
 * @generated SignedSource<<391c332ce3539e0384ef01c55845c601>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserSyncLoginUserMutation$variables = {};
export type UserSyncLoginUserMutation$data = {
  readonly loginUser: {
    readonly __typename: "User";
  };
};
export type UserSyncLoginUserMutation = {
  response: UserSyncLoginUserMutation$data;
  variables: UserSyncLoginUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSyncLoginUserMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "loginUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "MutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSyncLoginUserMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "loginUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e948ce73d012a12344722b0ec1cba034",
    "id": null,
    "metadata": {},
    "name": "UserSyncLoginUserMutation",
    "operationKind": "mutation",
    "text": "mutation UserSyncLoginUserMutation {\n  loginUser {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "631f1e93a523c4a628d5c54022275748";

export default node;
