/**
 * @generated SignedSource<<7d46b9d26da51256309490836d1f3df7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSyncQuery$variables = {};
export type UserSyncQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"UserSync_viewer">;
  };
};
export type UserSyncQuery = {
  response: UserSyncQuery$data;
  variables: UserSyncQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSyncQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSync_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSyncQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSuperAdmin",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd99f859ef60c738b3e9ebd97fe6a2b3",
    "id": null,
    "metadata": {},
    "name": "UserSyncQuery",
    "operationKind": "query",
    "text": "query UserSyncQuery {\n  viewer {\n    ...UserSync_viewer\n  }\n}\n\nfragment UserSync_viewer on Viewer {\n  me {\n    id\n    email\n    isSuperAdmin\n  }\n}\n"
  }
};

(node as any).hash = "48a784c6446248b5873e246468225ce2";

export default node;
