import TabsWrapper from 'components/tabs/TabsWrapper'
import paths from 'paths'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  showEmployeeGroups?: boolean
}

/**
 * A component to render a tab navigation for the user management pages. If a user
 * ID is provided in the URL, the navigation will not include the settings tab.
 */
export default function UserManagementNavigation({
  showEmployeeGroups,
}: Props) {
  const params = useParams<{ userId: string }>()

  const tabItems = useMemo(
    () => [
      {
        key: 'apps',
        text: 'Apps',
        to: paths.userApps({
          userId: params.userId,
        }),
      },
      {
        key: 'organizations',
        text: 'Organizations',
        to: paths.userOrganizations({
          userId: params.userId,
        }),
      },
      ...(showEmployeeGroups
        ? [
            {
              key: 'employeeGroups',
              text: 'Employee groups',
              to: paths.userTenant({
                userId: params.userId,
                area: 'employee-groups',
              }),
            },
          ]
        : []),
      // Super admins cannot access user settings pages
      ...(!!params.userId
        ? []
        : [
            {
              key: 'settings',
              text: 'Settings',
              to: paths.settings(),
            },
          ]),
    ],
    [params.userId, showEmployeeGroups],
  )

  return <TabsWrapper items={tabItems} />
}
