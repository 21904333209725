import * as Sentry from '@sentry/browser'
import AppEntry from 'AppEntry'
import Auth0PortalProvider from 'Auth0PortalProvider'
import { ErrorFallback } from 'components/error'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { EmailConfirmationPendingScene } from 'scenes/emailConfirmation'
import UnsupportedBrowserScene from 'scenes/unsupportedBrowser/UnsupportedBrowserScene'
import { isSupportedBrowser } from 'utils/browserSupport'
import { StaticNavigateProvider } from 'utils/navigate'

export default function App() {
  const renderApp = () => {
    if (isSupportedBrowser()) {
      return (
        <Routes>
          <Route
            path="/email-verification-pending"
            element={<EmailConfirmationPendingScene />}
          />
          <Route path="*" element={<AppEntry />} />
        </Routes>
      )
    } else {
      return <UnsupportedBrowserScene />
    }
  }

  const handleAppError = (error: Error, errorInfo: React.ErrorInfo) => {
    Sentry.withScope((scope) => {
      Object.entries(errorInfo).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
      Sentry.captureException(error)
    })
  }

  return (
    <BrowserRouter>
      <StaticNavigateProvider>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={handleAppError}
        >
          <Auth0PortalProvider>{renderApp()}</Auth0PortalProvider>
        </ErrorBoundary>
      </StaticNavigateProvider>
    </BrowserRouter>
  )
}
