import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef } from 'react'

type Params = {
  /** The user's unique identifier, i.e. their user ID */
  key?: string
  /** The user's email address */
  email?: string
  /** The user's name */
  name?: string
  /**
   * Whether the app is running in Cypress. This should come from a check to
   * window.Cypress, which is set by Cypress during test runs.
   */
  isRunningCypress?: boolean
}

/*
 * A hook to invoke `identify` on a LaunchDarkly SDK client to create a user's context. Once the
 * user identifiers (key, email and name) are defined, `identify` is called.
 */
export function useInitLD({ key, email, name, isRunningCypress }: Params) {
  const ldClient = useLDClient()
  // NOTE: using ref here to avoid triggering a re-render and identifying the user twice, as LD advises
  // against doing so. Using a boolean state would trigger a second render
  const readyRef = useRef(false)

  useEffect(() => {
    // We don't need to identify the user when running Cypress tests
    if (isRunningCypress) {
      return
    }

    const isReady = readyRef.current
    // Identify the user once the client is ready and the user identifiers are defined
    if (!isReady && ldClient && key && email && name) {
      readyRef.current = true
      ldClient.identify({ key, email, name })
    }
  }, [ldClient, key, email, name, isRunningCypress])

  return { ldClient, isReady: readyRef.current && !!ldClient }
}
