import React from 'react'
import { PropsWithChildren, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { StaticNavigateContext } from './context'

export default function StaticNavigateProvider({
  children,
}: PropsWithChildren) {
  const navigate = useNavigate()
  const navigateRef = useRef(navigate)

  return (
    <StaticNavigateContext.Provider value={navigateRef}>
      {children}
    </StaticNavigateContext.Provider>
  )
}
