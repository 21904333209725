import { Loader } from '@portal/components'
import React from 'react'

import { MainLayout } from '../layout'

/**
 * This is used to show a full page loader
 */
export const FullPageLoader: React.FC = () => {
  return (
    <MainLayout>
      <div className="flex h-screen items-center justify-center">
        <Loader />
      </div>
    </MainLayout>
  )
}

export default FullPageLoader
