/**
 * @generated SignedSource<<aaff06bc1f3fb39c538c6a79dc9a3ddb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalAppsMenuItemQuery$variables = {};
export type PersonalAppsMenuItemQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PersonalAppsMenuItem_viewer">;
  };
};
export type PersonalAppsMenuItemQuery = {
  response: PersonalAppsMenuItemQuery$data;
  variables: PersonalAppsMenuItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonalAppsMenuItemQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PersonalAppsMenuItem_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonalAppsMenuItemQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "App",
                "kind": "LinkedField",
                "name": "apps",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "992bd53cb4d9697ceefe559f6de41ce1",
    "id": null,
    "metadata": {},
    "name": "PersonalAppsMenuItemQuery",
    "operationKind": "query",
    "text": "query PersonalAppsMenuItemQuery {\n  viewer {\n    ...PersonalAppsMenuItem_viewer\n  }\n}\n\nfragment PersonalAppsMenuItem_viewer on Viewer {\n  me {\n    id\n    apps {\n      id\n      slug\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9ef0c191cdda6e7251271524d4f150b";

export default node;
