import graphql__b476bf761cf8fbda1895 from "./__generated__/EmployeeGroupManagementRouterQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import React, { Suspense, useEffect } from 'react'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'

import { EmployeeGroupManagementRouterQuery } from './__generated__/EmployeeGroupManagementRouterQuery.graphql'
import { EmployeeGroupContext } from './context'
import EmployeeGroupManagementLayout from './EmployeeGroupManagementLayout'

const EmployeeGroupCustomers = React.lazy(() => import('./tabs/customers'))
const EmployeeGroupEmployeeMembers = React.lazy(
  () => import('./tabs/employees'),
)
const EmployeeGroupSettingsScene = React.lazy(() => import('./tabs/settings'))

const query = graphql__b476bf761cf8fbda1895

export default function EmployeeGroupManagementRouterContainer() {
  const { employeeGroupSlug } = useParams<{ employeeGroupSlug: string }>()

  const [queryRef, loadQuery] =
    useQueryLoader<EmployeeGroupManagementRouterQuery>(query)

  useEffect(() => {
    if (employeeGroupSlug) {
      loadQuery(
        {
          slug: employeeGroupSlug,
        },
        { fetchPolicy: 'store-and-network' },
      )
    }
  }, [employeeGroupSlug, loadQuery])

  if (!queryRef || !employeeGroupSlug) {
    return null
  }

  return (
    <EmployeeGroupManagementRouter
      queryRef={queryRef}
      slug={employeeGroupSlug}
    />
  )
}

type Props = {
  queryRef: PreloadedQuery<EmployeeGroupManagementRouterQuery>
  slug: string
}

function EmployeeGroupManagementRouter({ queryRef, slug }: Props) {
  const navigate = useNavigate()
  const {
    viewer: { employeeGroup },
  } = usePreloadedQuery(query, queryRef)

  useEffect(() => {
    if (!employeeGroup) {
      navigate(`/employee-groups?slugNotFound=${slug}`, { replace: true })
    }
  }, [employeeGroup, slug, navigate])

  if (!employeeGroup) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <EmployeeGroupContext.Provider value={employeeGroup}>
        <Routes>
          <Route path="" element={<EmployeeGroupManagementLayout />}>
            <Route path="" element={<Navigate to="customers" replace />} />
            <Route path="customers" element={<EmployeeGroupCustomers />} />
            <Route
              path="employees"
              element={<EmployeeGroupEmployeeMembers />}
            />
            <Route path="settings" element={<EmployeeGroupSettingsScene />} />
          </Route>
        </Routes>
      </EmployeeGroupContext.Provider>
    </Suspense>
  )
}
