import {
  MessagePanel,
  ProgressSpinner,
  useDetectTheme,
} from '@portal/components'
import React, { useContext, useLayoutEffect, useState } from 'react'

import AppContext from '../../context'
import AppSettingsContext from './context'

export const EXPECTED_INGRESS_DOMAIN_READY_TIME_MS = 30000
export const STEP_MS = 200

/** Show a loading bar for the ingress domain loading status, or an error panel if the ingress
 * domain is in error state. */
const IngressDomainLoadingStatus = () => {
  const { tailwind } = useContext(AppContext)
  const {
    isDomainReady,
    isDomainInError,
    isDomainInitiallyReady,
    queryAttempts,
  } = useContext(AppSettingsContext)
  const [timeLoading, setTimeLoading] = useState(0)
  const theme = useDetectTheme()
  const didStartDomainDuringSession =
    !isDomainInitiallyReady && !!queryAttempts && isDomainReady

  useLayoutEffect(() => {
    if (
      !isDomainReady &&
      !isDomainInError &&
      timeLoading < EXPECTED_INGRESS_DOMAIN_READY_TIME_MS
    ) {
      const timeoutId = setTimeout(() => {
        setTimeLoading((curentTimeLoading) => curentTimeLoading + STEP_MS)
      }, STEP_MS)

      return () => clearTimeout(timeoutId)
    }
  }, [timeLoading, isDomainReady, isDomainInError])

  if (isDomainInError) {
    return (
      <div className="pb-4">
        <MessagePanel
          message="We've encountered an issue configuring your Big Peer and we're working to resolve it. Big Peer settings for your app are temporarily unavailable. Please check back later."
          variant="danger"
          icon="ExclamationCircle"
        />
      </div>
    )
  }

  if (didStartDomainDuringSession) {
    return (
      <div className="pb-4">
        <MessagePanel
          message="Your Big Peer is configured. You can now access Big Peer specific areas like the Data Browser, Authentication Mode & Webhook Settings and Live Query Settings."
          variant="success"
          icon="CheckCircle"
        />
      </div>
    )
  }

  if (isDomainReady || isDomainInitiallyReady || !queryAttempts) {
    return null
  }

  return (
    <div className="pb-4">
      <div className="border-l-border-normal flex rounded-none border-l-2 bg-background-surface p-3">
        <div className="h-6 w-6">
          <ProgressSpinner
            strokeWidth={3}
            total={EXPECTED_INGRESS_DOMAIN_READY_TIME_MS}
            progress={timeLoading}
            progressColor={tailwind.theme.colors.primary.DEFAULT}
            remainingColor={
              theme === 'light'
                ? tailwind.theme.colors.primary[200]
                : tailwind.theme.colors.gray[600]
            }
          />
        </div>
        <div className="ml-3 items-center md:flex md:justify-between">
          <p
            className="text-foreground-subtle"
            data-testid="configuringMessage"
          >
            We&apos;re setting up your Big Peer. This may take up to a few
            minutes.
          </p>
        </div>
      </div>
    </div>
  )
}

export default IngressDomainLoadingStatus
