import paths from 'paths'
import { AppManagementArea } from 'paths/link'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

type RedirectParams = {
  area?: AppManagementArea
}

export default function AppManagementRouterRedirect({
  area = 'settings',
}: RedirectParams) {
  const params = useParams<{
    organizationSlug?: string
    appSlug: string
    userId?: string
  }>()

  return (
    <Navigate
      to={paths.appManagement({
        ...params,
        appSlug: params.appSlug || '',
        tenantId: params.userId,
        area,
      })}
      replace
    />
  )
}
