import { Auth0Provider } from '@auth0/auth0-react'
import React, { PropsWithChildren } from 'react'

/** Auth0 portal provider that acts as a wrapper around the Auth0Provider
 * and is capable of performing redirections to a redirectUrl field passed by the
 * AppEntry component inside of the appState.
 */
const Auth0PortalProvider = ({ children }: PropsWithChildren) => {
  const windowEnvironment = window._env_

  return (
    <Auth0Provider
      redirectUri={window.location.origin}
      clientId={windowEnvironment.AUTH0_CLIENT_ID!}
      domain={windowEnvironment.AUTH0_DOMAIN!}
      audience={windowEnvironment.AUTH0_API_IDENTIFIER!}
      onRedirectCallback={(appState) => {
        if (!!appState?.redirectUrl) {
          window.location.replace(
            `${window.location.origin}${appState.redirectUrl}${
              appState.searchParams || ''
            }`,
          )
        }
      }}
      useRefreshTokens
      scope="openid profile"
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0PortalProvider
