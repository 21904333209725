/**
 * @generated SignedSource<<104cfd42ddfa900df11ea3053999b86b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NavigationBarQuery$variables = {};
export type NavigationBarQuery$data = {
  readonly viewer: {
    readonly me: {
      readonly hubspotContactId: string;
    } | null;
  };
};
export type NavigationBarQuery = {
  response: NavigationBarQuery$data;
  variables: NavigationBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubspotContactId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationBarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavigationBarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af4bad39ed47f121cf00c91e15bc1f9d",
    "id": null,
    "metadata": {},
    "name": "NavigationBarQuery",
    "operationKind": "query",
    "text": "query NavigationBarQuery {\n  viewer {\n    me {\n      hubspotContactId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "428c2b89fe8b6e82a8bb4b29efca14ad";

export default node;
