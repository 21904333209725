/**
 * @generated SignedSource<<c7cab23113ce70d8ccb9eb05a414ecf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmployeeGroupManagementRouterQuery$variables = {
  slug: string;
};
export type EmployeeGroupManagementRouterQuery$data = {
  readonly viewer: {
    readonly employeeGroup: {
      readonly description: string | null;
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly viewerCanLeave: boolean;
      readonly viewerIsMember: boolean;
      readonly viewerIsOwner: boolean;
    } | null;
  };
};
export type EmployeeGroupManagementRouterQuery = {
  response: EmployeeGroupManagementRouterQuery$data;
  variables: EmployeeGroupManagementRouterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ],
        "concreteType": "EmployeeGroup",
        "kind": "LinkedField",
        "name": "employeeGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanLeave",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsMember",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsOwner",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmployeeGroupManagementRouterQuery",
    "selections": (v1/*: any*/),
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmployeeGroupManagementRouterQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "85419f43c65899fea91d5b44129e34ea",
    "id": null,
    "metadata": {},
    "name": "EmployeeGroupManagementRouterQuery",
    "operationKind": "query",
    "text": "query EmployeeGroupManagementRouterQuery(\n  $slug: String!\n) {\n  viewer {\n    employeeGroup(slug: $slug) {\n      id\n      name\n      slug\n      description\n      viewerCanLeave\n      viewerIsMember\n      viewerIsOwner\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64c5a693f9cfe8421a9974b235c8c8dc";

export default node;
