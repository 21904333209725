import graphql__f0df66142af48acb48fd from "./__generated__/UserMenuQuery.graphql.ts";import { useAuth0 } from '@auth0/auth0-react'
import { Popover } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { icons, RawLink } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import paths from 'paths'
import React, { useContext, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'

import AppContext from '../../context'
import { UserMenuQuery } from './__generated__/UserMenuQuery.graphql'

const windowEnvironment = window._env_

const query = graphql__f0df66142af48acb48fd

const { Add, ArrowUpRight, X } = icons

type Props = {
  showLinks?: boolean
}

const baseLinkClass =
  'flex h-12 w-full items-center px-4 text-base text-foreground-normal'
const linkClass = 'bg-background-overlay hover:bg-background-overlay-hovered'
const superAdminLinkClass = 'hover:bg-fill-promo-secondary'
const focusStyles =
  'outline-none focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-focus-outline'
const iconClass =
  'h-4 w-4 text-foreground-subtle group-hover:text-foreground-normal group-focus-visible:text-foreground-normal'

const UserMenu = ({ showLinks }: Props) => {
  const { isSuperAdmin } = useContext(AppContext)
  const { logout } = useAuth0()
  const { viewer } = useLazyLoadQuery<UserMenuQuery>(
    query,
    {},
    { fetchPolicy: 'store-and-network' },
  )
  const [userImageError, setUserImageError] = useState(false)

  const hasIncomingAppRequests =
    !!(viewer.me?.incomingAppRequests || []).length ||
    (viewer.me?.organizations || []).some(
      (organization) => !!(organization.incomingAppRequests || []).length,
    )

  const hasIncomingOrganizationRequests = (viewer.me?.organizations || []).some(
    (organization) =>
      !!organization.ownershipChangeRequest &&
      organization.ownershipChangeRequest.toMember.user.id === viewer.me!.id,
  )

  const hasIncomingOrganizationInvitations = (
    viewer.me?.incomingOrganizationInvitations || []
  ).some(({ rejectedAt, acceptedAt }) => !rejectedAt && !acceptedAt)

  const showNotificationIndicator =
    hasIncomingAppRequests ||
    hasIncomingOrganizationRequests ||
    hasIncomingOrganizationInvitations

  return (
    <Popover as="div" className="sm:relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              'flex h-12 w-8 items-center justify-center sm:ml-3',
              focusStyles,
            )}
            data-testid="userMenuButton"
          >
            {open ? (
              <X className="h-5 w-5 text-foreground-normal sm:hidden" />
            ) : (
              <Bars3Icon className="h-5 w-5 text-foreground-normal sm:hidden" />
            )}
            {viewer?.me?.imageUrl && !userImageError && (
              <img
                className="hidden h-8 w-8 rounded-full sm:block"
                src={viewer?.me?.imageUrl}
                onError={() => setUserImageError(true)}
                alt={
                  viewer?.me?.firstName || viewer?.me?.lastName
                    ? `Image of ${viewer?.me?.firstName} ${viewer?.me?.lastName}`
                    : `User avatar`
                }
                data-testid="userMenuAvatar"
              />
            )}
            {(!viewer?.me?.imageUrl || userImageError) && (
              <span className="hidden h-8 w-8 rounded-full bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-green-300 via-blue-500 to-purple-600 sm:block" />
            )}
            {showNotificationIndicator && (
              <div
                className="absolute right-0 top-2 h-2 w-2 animate-pulse rounded-full bg-fill-brand-primary"
                data-testid="notificationIndicator"
              />
            )}
          </Popover.Button>
          <Popover.Panel
            className="absolute left-0 z-50 w-full bg-background-overlay py-2 shadow-lg sm:left-auto sm:right-0 sm:w-[320px]"
            data-testid="userMenuPanel"
          >
            <div className="px-4 py-2">
              <span className="mb-1 block truncate whitespace-nowrap text-base font-medium text-foreground-normal">
                {viewer?.me?.firstName || viewer?.me?.lastName
                  ? `${viewer?.me?.firstName} ${viewer?.me?.lastName}`
                  : `Missing name`}
              </span>
              <span className="mb-1 block truncate whitespace-nowrap text-base text-foreground-subtle">
                {viewer?.me?.email}
              </span>
              {windowEnvironment.BUILD_VERSION && (
                <span className="block truncate whitespace-nowrap text-base text-foreground-subtle">
                  Build: {windowEnvironment.BUILD_VERSION}
                </span>
              )}
            </div>
            <div className="py-2">
              {showNotificationIndicator && (
                <Popover.Button
                  as={RawLink}
                  to={paths.notifications()}
                  className={classNames(
                    'group justify-between',
                    baseLinkClass,
                    linkClass,
                    focusStyles,
                  )}
                >
                  <span>Notifications</span>
                  <div
                    className="block h-2 w-2 animate-pulse rounded-full bg-primary-500"
                    data-testid="notificationIndicator"
                  />
                </Popover.Button>
              )}
              <Popover.Button
                as={RawLink}
                to={paths.settings()}
                className={classNames(baseLinkClass, linkClass, focusStyles)}
              >
                Settings
              </Popover.Button>
              {showLinks && (
                <Popover.Button
                  as={RawLink}
                  to={paths.createOrganization()}
                  className={classNames(
                    'group justify-between',
                    baseLinkClass,
                    linkClass,
                    focusStyles,
                  )}
                >
                  <span>New organization</span>
                  <Add className={iconClass} />
                </Popover.Button>
              )}
            </div>
            {isSuperAdmin && (
              <div className="border-border-promo border-t bg-fill-promo-secondary py-2 ">
                <Popover.Button
                  as={RawLink}
                  to={paths.users()}
                  className={classNames(
                    baseLinkClass,
                    superAdminLinkClass,
                    focusStyles,
                  )}
                >
                  Users
                </Popover.Button>
                <Popover.Button
                  as={RawLink}
                  to={paths.findOrganization()}
                  className={classNames(
                    baseLinkClass,
                    superAdminLinkClass,
                    focusStyles,
                  )}
                >
                  Find organization
                </Popover.Button>
                <Popover.Button
                  as={RawLink}
                  to={paths.findApp()}
                  className={classNames(
                    baseLinkClass,
                    superAdminLinkClass,
                    focusStyles,
                  )}
                >
                  Find app
                </Popover.Button>
              </div>
            )}
            <div className="border-border-normal border-t pt-2">
              <Popover.Button
                as={RawLink}
                href={paths.dittoDocs()}
                className={classNames(
                  'group justify-between sm:hidden',
                  baseLinkClass,
                  linkClass,
                  focusStyles,
                )}
                isBlank
              >
                <span>Docs</span>
                <ArrowUpRight className={iconClass} />
              </Popover.Button>
              {/* TODO(aaron): Once we have a global changelog again uncomment this  */}
              {/* <Popover.Button
                as={RawLink}
                href={paths.dittoDocs('changelog')}
                className={classNames(
                  'group justify-between sm:hidden',
                  baseLinkClass,
                  linkClass,
                  focusStyles,
                )}
                isBlank
              >
                <span>Changelog</span>
                <ArrowUpRightIcon className={iconClass} />
              </Popover.Button> */}
              <Popover.Button
                as={RawLink}
                href={paths.support()}
                className={classNames(
                  'group justify-between sm:hidden',
                  baseLinkClass,
                  linkClass,
                  focusStyles,
                )}
                isBlank
              >
                <span>Support</span>
                <ArrowUpRight className={iconClass} />
              </Popover.Button>
              <Popover.Button
                as={RawLink}
                href="https://www.ditto.live/"
                className={classNames(
                  'group justify-between',
                  baseLinkClass,
                  linkClass,
                  focusStyles,
                )}
                isBlank
              >
                <span>Ditto homepage</span>
                <ArrowUpRight className={iconClass} />
              </Popover.Button>
              {viewer.me?.hubspotContactId && (
                <Popover.Button
                  as={RawLink}
                  to={paths.feedback()}
                  className={classNames(baseLinkClass, linkClass, focusStyles)}
                >
                  Feedback
                </Popover.Button>
              )}
              <button
                className={classNames(baseLinkClass, linkClass, focusStyles)}
                onClick={() => {
                  logout({ returnTo: window.location.origin })
                }}
              >
                Log out
              </button>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default UserMenu
