import { createContext, MutableRefObject, useContext } from 'react'
import { NavigateFunction } from 'react-router-dom'

export const StaticNavigateContext =
  createContext<MutableRefObject<NavigateFunction> | null>(null)

/**
 * A hook to return a reference to the navigate function. This is to bypass a bit of a
 * limitation with react-router-dom's useNavigate hook. This should only be used in situations
 * where you _just_ need to push a new route onto the history stack.
 *
 * See the following for more: https://github.com/remix-run/react-router/issues/7634#issuecomment-1025113528
 */
export const useNavigateRef = (): NavigateFunction => {
  const navigateRef = useContext(StaticNavigateContext)
  if (!navigateRef?.current)
    throw new Error('StaticNavigateContext context is not initialized')

  return navigateRef.current
}
