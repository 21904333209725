/**
 * @generated SignedSource<<c4f1777fceb8c96dc12e105f53f3f371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserManagementLayoutQuery$variables = {
  skipMe: boolean;
  skipUser: boolean;
  userId: string;
};
export type UserManagementLayoutQuery$data = {
  readonly viewer: {
    readonly me?: {
      readonly id: string;
      readonly isSuperAdmin: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"UserManagementHeader_userInfo">;
    } | null;
    readonly user?: {
      readonly id: string;
      readonly isSuperAdmin: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"UserManagementHeader_userInfo">;
    } | null;
  };
};
export type UserManagementLayoutQuery = {
  response: UserManagementLayoutQuery$data;
  variables: UserManagementLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipMe"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipUser"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSuperAdmin",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserManagementHeader_userInfo"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v7 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserManagementLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "skipMe",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "skipUser",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserManagementLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "skipMe",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "skipUser",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b5f9487b3dafe8e299e970b2f2a50dc",
    "id": null,
    "metadata": {},
    "name": "UserManagementLayoutQuery",
    "operationKind": "query",
    "text": "query UserManagementLayoutQuery(\n  $userId: ID!\n  $skipUser: Boolean!\n  $skipMe: Boolean!\n) {\n  viewer {\n    me @skip(if: $skipMe) {\n      id\n      isSuperAdmin\n      ...UserManagementHeader_userInfo\n    }\n    user(id: $userId) @skip(if: $skipUser) {\n      id\n      isSuperAdmin\n      ...UserManagementHeader_userInfo\n    }\n  }\n}\n\nfragment UserManagementHeader_userInfo on User {\n  firstName\n  lastName\n}\n"
  }
};
})();

(node as any).hash = "fb42b72052f5390ee18e3e1514b0fd3d";

export default node;
