import { useAppContext } from 'context'
import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

const CreateUserAccessGrantScene = React.lazy(
  () => import('./CreateUserAccessGrantScene'),
)
const UserAppsScene = React.lazy(() => import('./UserAppsScene'))

export default function UserAppsRouter() {
  const { isSuperAdmin } = useAppContext()

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="" element={<UserAppsScene />} />
        {/* TODO(aaron): eventually we will support customers creating grant requests, however it is only for employees for now */}
        {isSuperAdmin && (
          <Route
            path="request-access"
            element={<CreateUserAccessGrantScene />}
          />
        )}
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </Suspense>
  )
}
