import { Menu } from '@headlessui/react'
import { icons, RawLink } from '@portal/components'
import classNames from 'classnames'
import paths from 'paths'
import React from 'react'

const { Add } = icons

export default function CreateOrganizationMenuItem() {
  return (
    <Menu.Item>
      {({ active }) => (
        <RawLink
          to={paths.createOrganization()}
          className={classNames(
            'group flex h-12 items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
            { 'bg-background-overlay-hovered': active },
            { 'bg-background-overlay': !active },
          )}
        >
          <span>New organization</span>
          <Add
            className={classNames(
              'h-4 w-4 group-hover:text-foreground-normal',
              { 'text-foreground-normal': active },
              { 'text-foreground-subtle': !active },
            )}
          />
        </RawLink>
      )}
    </Menu.Item>
  )
}
