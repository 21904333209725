import graphql__3c082320d4871952c9e0 from "./__generated__/AppManagementSceneQuery.graphql.ts";
import graphql__89b25062160c09d072c4 from "./__generated__/AppManagementSceneVisitMutation.graphql.ts";import { usePendingCursorOperation } from '@dittolive/react-ditto'
import graphql from 'babel-plugin-relay/macro'
import { AnnouncementsContainer } from 'components/announcement'
import { SystemCollections } from 'components/dittoAppConnection'
import { usePrefetchQuery } from 'components/relay'
import TabsWrapper from 'components/tabs'
import { useApplyTenant } from 'components/tenant'
import AppContext from 'context'
import paths from 'paths'
import React, { Suspense, useContext, useEffect, useMemo } from 'react'
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { useFeatureFlags } from 'utils/featureFlags'

import useInitializeKafkaConfiguration from '../../dittoPortalProvider/useInitializeKafkaConfiguration'
import { AppManagementSceneQuery } from './__generated__/AppManagementSceneQuery.graphql'
import { AppManagementSceneVisitMutation } from './__generated__/AppManagementSceneVisitMutation.graphql'
import AppHeader from './AppHeader'
import AppManagementContextProvider from './AppManagementContextProvider'
import AppManagementRouter from './AppManagementRouter'
import {
  AppAuthAPIConnectionSectionQuery,
  AppAuthRouterQuery,
} from './tabs/auth'
import {
  AppDangerSectionQuery,
  AppSettingsFormQuery,
  TransferAppSectionQuery,
} from './tabs/settings'

export const query = graphql__3c082320d4871952c9e0

const visitMutation = graphql__89b25062160c09d072c4

type Props = {
  /** Current app ID. */
  appId: string
  /** True if the domain status is ready since the first moment the status is queried. */
  isDomainInitiallyReady: boolean
  /** True if the ingress domain for the app is ready. */
  isDomainReady: boolean
  /** True if the ingress domain for the app is in error state. */
  isDomainInError: boolean
  /** Number of times the domain status has been queried. */
  queryAttempts: number
}

/** Scene for managing apps */
export default function AppManagementScene({
  appId,
  isDomainInitiallyReady,
  isDomainInError,
  isDomainReady,
  queryAttempts,
}: Props) {
  const params = useParams<{
    organizationSlug: string
    appSlug: string
    userId: string
  }>()
  const { isSuperAdmin } = useContext(AppContext)
  const { viewer } = useLazyLoadQuery<AppManagementSceneQuery>(
    query,
    { appId },
    { fetchPolicy: 'store-and-network' },
  )
  const { appWebhooks, deviceDashboard, metrics } = useFeatureFlags()
  const { documents: webhooks } = usePendingCursorOperation({
    path: appId,
    collection: SystemCollections.APP_LIVE_QUERY_WEBHOOKS_COLLECTION,
  })
  const viewerCanUpdateApp = viewer.appById?.viewerCanUpdate ?? false
  const viewerCanReadAppData = viewer.appById?.viewerCanReadAppData ?? false
  const viewerCanReadLicense = viewer.appById?.viewerCanReadLicense ?? false
  const viewerCanReadApiKey = viewer.appById?.viewerCanReadApiKey ?? false
  const isDedicatedCluster = !!viewer.appById?.hydraCluster?.isDedicated
  const allowAppWebhooks =
    isSuperAdmin || isDedicatedCluster || appWebhooks || webhooks.length > 0
  const allowMetrics =
    !!(isSuperAdmin || viewer.appById?.viewerCanAccessMetrics) && !!metrics
  useInitializeKafkaConfiguration(appId, isDedicatedCluster, isDomainReady)
  useApplyTenant(params.organizationSlug!)
  const { prefetchQueries } = usePrefetchQuery()

  const [markAppAsVisited] =
    useMutation<AppManagementSceneVisitMutation>(visitMutation)

  useEffect(() => {
    if (viewer.appById) {
      markAppAsVisited({
        variables: { appId },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer.appById])

  const showCollections = viewerCanReadAppData || !!isSuperAdmin
  const showDevices = deviceDashboard && viewerCanReadAppData

  const atLeastOneAuthSection =
    viewerCanReadLicense || viewerCanReadApiKey || viewerCanReadAppData
  const showAuth = atLeastOneAuthSection || !!isSuperAdmin

  const tabItems = useMemo(
    () => [
      {
        key: 'settings',
        text: 'Settings',
        to: paths.appManagement({
          ...params,
          appSlug: params.appSlug || '',
          tenantId: params.userId,
          area: 'settings',
        }),
        onMouseEnter: () =>
          prefetchQueries(
            [
              AppSettingsFormQuery,
              AppDangerSectionQuery,
              TransferAppSectionQuery,
            ],
            {
              appId,
            },
          ),
      },
      ...(isDomainReady && showCollections
        ? [
            {
              key: 'collections',
              text: 'Collections',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'collections',
              }),
            },
          ]
        : []),
      ...(isDomainReady && showDevices
        ? [
            {
              key: 'devices',
              text: 'Devices',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'devices',
              }),
            },
          ]
        : []),
      ...(allowMetrics
        ? [
            {
              key: 'metrics',
              text: 'Metrics',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'metrics',
              }),
            },
          ]
        : []),

      ...(showAuth
        ? [
            {
              key: 'auth',
              text: 'Auth',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'auth',
              }),
              onMouseEnter: () =>
                prefetchQueries(
                  [AppAuthAPIConnectionSectionQuery, AppAuthRouterQuery],
                  { appId },
                ),
            },
          ]
        : []),
      ...(process.env.NODE_ENV !== 'production'
        ? [
            {
              key: 'issues',
              text: 'Issues',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'issues',
              }),
            },
          ]
        : []),
      // NOTE: this feature flag check was added as part of https://github.com/getditto/cloud-services/issues/1984
      ...(isDomainReady && allowAppWebhooks
        ? [
            {
              key: 'webhooks',
              text: 'Live Query Settings',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'webhooks',
              }),
            },
          ]
        : []),
    ],
    [
      params,
      isDomainReady,
      allowAppWebhooks,
      showDevices,
      allowMetrics,
      prefetchQueries,
      appId,
      showCollections,
      showAuth,
    ],
  )

  const renderAppContents = () => (
    <>
      <AnnouncementsContainer announcements={viewer.appById?.announcements} />
      <Suspense fallback={null}>
        <AppHeader app={viewer.appById!} />
      </Suspense>
      {tabItems.length > 1 && <TabsWrapper items={tabItems} />}
      <Suspense fallback={null}>
        <AppManagementRouter appId={appId} />
      </Suspense>
    </>
  )

  return (
    <AppManagementContextProvider
      appId={appId}
      isDomainInitiallyReady={isDomainInitiallyReady}
      isDomainReady={isDomainReady}
      isDomainInError={isDomainInError}
      queryAttempts={queryAttempts}
      isDedicatedCluster={isDedicatedCluster}
      allowAppWebhooks={allowAppWebhooks}
      viewerCanAccessMetrics={allowMetrics}
      viewerCanReadAppData={viewerCanReadAppData}
      viewerCanReadLicense={viewerCanReadLicense}
      viewerCanReadApiKey={viewerCanReadApiKey}
      viewerCanUpdateApp={viewerCanUpdateApp}
    >
      {renderAppContents()}
    </AppManagementContextProvider>
  )
}
